@font-face {
    font-family: "WAG Symbols Tokyo";
    src: url("./Assets/Fonts/WAG Symbols Tokyo.woff2") format("woff2");
}

@font-face {
    font-family: "WAG Symbols";
    src: url("./Assets/Fonts/WAG Symbols.woff2") format("woff2");
}

.gs-wag-font {
    font-family: "WAG Symbols, WAG Symbols Tokyo";
    font-size: xx-large;
}

