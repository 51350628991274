@font-face {
    font-family: "WAG Symbols Tokyo";
    src: url("../../Assets/Fonts/WAG Symbols Tokyo.woff2") format("woff2");
}

@font-face {
    font-family: "WAG Symbols";
    src: url("../../Assets/Fonts/WAG Symbols.woff2") format("woff2");
}

.gs-wag-inout-keyboard {
    border-width: 2px;
    border-style: solid;
    border-color: peru;
}

.keyboard-button {
    font-family: "WAG Symbols";
    font-size: xx-large;
}