.deduction {
    background-color: #fff3cd;
    font-weight: bold;
}

.award {
    background-color: #dbedd4;
    font-weight: bold;
}

.added {
    background-color: #d4edda;
}

.changed {
    background-color: #d1ecf1;
}

.wag {
    font-family: "WAG Symbols";
    font-size: 24px;
    font-weight: bold;
}

.noBullet {
    list-style: none;
    margin: 0;
    padding: 0;
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.fit {
    width: auto !important;
    table-layout: auto !important;
}

.narrowRow {
    line-height: 1em;
    margin: 0em;
}

.narrowCol {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
}

.narrowListItem {
    margin-bottom: 0em;
}

.level1Indent {
    list-style-type: none;
    text-indent: -0.8em;
}

.level1Indent > li:before {
    list-style-type: none;
    content: "– ";
}

.level2Indent {
    margin-left: 40px;
}

.level3Indent {
    margin-left: 60px;
    list-style-type: circle;
}

.headerRow {
    background-color: lightgrey;
}

.inversed {
    background-color: black;
    color: white;
}

h3 {
    margin-top: 3rem;
}

h4 {
    margin-top: 2rem;
}

#toc_container {
    background: #f9f9f9 none repeat scroll 0 0;
    border: 1px solid #aaa;
    display: table;
    font-size: 95%;
    margin-bottom: 1em;
    padding: 5px;
    width: auto;
}

.toc_title {
    font-weight: 700;
    text-align: center;
}

#toc_container li, #toc_container ul, #toc_container ul li{
    list-style: outside none none !important;
}

.anchor {
    display: block;
    position: relative;
    top: -35px;
    visibility: hidden;    
}